import React from 'react';
import ReactDOM from "react-dom/client";
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { msalConfig } from "./authConfig.js";
import App from "./App";


/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
export const msalInstance = new PublicClientApplication(msalConfig);

/* Error uninitialized_public_client_application is thrown if the initialisation isn't included, but supposedly
 * we should be able to exclude it. 
 * TODO: Look into this */
msalInstance.initialize().then(() => {
    msalInstance.addEventCallback((event) => {
        if ((event.eventType === EventType.LOGIN_SUCCESS ||
             event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
             event.eventType === EventType.SSO_SILENT_SUCCESS) &&
             event.payload.account) {
    
            msalInstance.setActiveAccount(event.payload.account);
        }
    });
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App msalInstance={msalInstance} />
    </React.StrictMode>
);
