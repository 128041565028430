import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration of user flows
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_sign_in',
    },
    authorities: {
        signIn: {
            authority: 'https://aiotfiredoors.b2clogin.com/aiotfiredoors.onmicrosoft.com/b2c_1_sign_in',
        }
    },
    authorityDomain: 'aiotfiredoors.b2clogin.com',
};


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: 'fac69359-ef1f-4ee6-994b-4e4a20b1e857', 
        authority: b2cPolicies.authorities.signIn.authority, // Selects the sign up/sign in flow as default
        knownAuthorities: [b2cPolicies.authorityDomain], // Marks the B2C tenant's domain as trusted
        redirectUri: '/', // This URI must be registered on Azure Portal/App Registration
        postLogoutRedirectUri: '/logout', 
        navigateToLoginRequestUrl: true, // Return the user to the original request location after login
    },
    cache: {
        cacheLocation: 'sessionStorage', // LocalStorage allows user logins to persist between tabs
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false, // Disable WAM broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};


/**
 * API endpoints, scopes etc.
 */
export const protectedResources = {
    scopes: {
        read: ['https://aiotfiredoors.onmicrosoft.com/9d76b4e4-d0fc-480b-9e01-eecf053f67f9/sensors.read'],
    },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.scopes.read]
};