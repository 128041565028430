import { useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType, } from '@azure/msal-browser';
import React, { useEffect } from 'react'
import { Outlet } from "react-router-dom";
import { LinearProgress, Box } from "@mui/material";

const request = {
    scopes: ["openid", "offline_access"]
}

export default function Protected() {
    const { login, error } = useMsalAuthentication(InteractionType.Redirect, request);
    const isAuth = useIsAuthenticated();

    /* Returns the user to the login screen if they cancel an operation.
     * e.g. the user enters the "forgot password?" flow then cancels.
     */
    useEffect(() => {
        if (error?.errorMessage.includes("AADB2C90091")) // Error code for cancelling the forgot password flow
        {
            login(InteractionType.Redirect);
        }
    }, [login, error]);

    return (
        isAuth ? (
            <Outlet />
        ) : (
            <Box sx={{ width: "100%" }}>
                <LinearProgress />
            </Box>
        )
    );
}