import { Grid, Card as MUICard, CardHeader, CardContent, IconButton, Typography, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, styled } from '@mui/material';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useMsal } from '@azure/msal-react';
import { useQuery } from 'react-query';

import DataGrid from '../components/DataGrid';
import { deviceCols, faultCols, slots } from '../config/dataGridConfig';
import { fetchDeviceFaults, fetchDoorFaults, fetchDevices, triggerReport } from '../api/api';
import { useState } from 'react';

const Card = styled(MUICard)({
    borderLeft: "4px solid #009ca6",
});

export default function Home() {
    const { instance } = useMsal();
    const company = instance.getActiveAccount()?.idTokenClaims.extension_Organisation;

    const devicesQuery = useQuery({
        queryKey: ['deviceData', company],
        queryFn: () => fetchDevices(instance, company),
    });

    const deviceFaultsQuery = useQuery({
        queryKey: ['deviceFaults', company],
        queryFn: () => fetchDeviceFaults(instance, company),
    });

    const doorFaultsQuery = useQuery({
        queryKey: ['doorFaults', company],
        queryFn: () => fetchDoorFaults(instance, company),
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);

    function handleDialogOpen(params) {
        setDialogOpen(true);
        setSelectedSite(params.row.site_id);
    }

    function handleDialogClose() {
        setDialogOpen(false);
    }

    function generateReport() {
        triggerReport(instance, selectedSite);
        handleDialogClose();
    }

    const renderFaultTable = (query) => {
        if (query.isError) {
            return (
                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                    <ErrorIcon htmlColor="red" />
                    <Typography variant="h6">Error while fetching</Typography>
                </Stack>
            );
        } else if (query.isSuccess && query.data.length === 0) {
            return (
                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                    <CheckCircleIcon htmlColor="green" />
                    <Typography variant="h6">No faults detected</Typography>
                </Stack>
            );
        }

        return (
            <DataGrid
                slots={slots}
                rows={query.data ?? []}
                columns={faultCols}
                loading={query.isLoading || query.isRefetching}
            />
        );
    }

    const renderDevicesTable = () => {
        if (devicesQuery.isError) {
            return (
                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                    <ErrorIcon htmlColor="red" />
                    <Typography variant="h6">Error while fetching</Typography>
                </Stack>
            );
        } else if (devicesQuery.isSuccess && devicesQuery.data.length === 0) {
            return (
                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                    <ErrorIcon htmlColor="red" />
                    <Typography variant="h6">No devices registered</Typography>
                </Stack>
            );
        }

        return (
            <>
                <DataGrid
                    slots={slots}
                    rows={devicesQuery.data ?? []}
                    columns={deviceCols}
                    loading={devicesQuery.isLoading || devicesQuery.isRefetching}
                    onRowClick={handleDialogOpen}
                />
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                >
                    <DialogTitle>Generate a report?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Generate a report showing aggregated data for the site this sensor is mounted at.
                            The report will be sent as a PDF to the email address associated with your account.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Cancel</Button>
                        <Button onClick={generateReport}>Generate</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
                <Card>
                    <CardHeader title="Device faults" action={<IconButton onClick={deviceFaultsQuery.refetch}><RefreshOutlined /></IconButton>} />
                    <CardContent sx={{ padding: 1 }}>
                        {renderFaultTable(deviceFaultsQuery)}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Card>
                    <CardHeader title="Door faults" action={<IconButton onClick={doorFaultsQuery.refetch}><RefreshOutlined /></IconButton>} />
                    <CardContent sx={{ padding: 1 }}>
                        {renderFaultTable(doorFaultsQuery)}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Devices" action={<IconButton onClick={devicesQuery.refetch}><RefreshOutlined /></IconButton>} />
                    <CardContent sx={{ padding: 1 }}>
                        {renderDevicesTable()}
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
}
