async function callBackend(msalInstance, company, endpoint) {
    const request = {
        scopes: ['https://aiotfiredoors.onmicrosoft.com/9d76b4e4-d0fc-480b-9e01-eecf053f67f9/sensors.read'],
        account: msalInstance.getActiveAccount(),
    };

    let url;
    if (process.env.NODE_ENV === 'production') {
        url = "";
    } else if (process.env.NODE_ENV === 'test') {
        url = "https://fire-door-backend.azurewebsites.net";
    } else {
        url = "http://127.0.0.1:8000";
    }

    const token = (await msalInstance.acquireTokenSilent(request)).accessToken;

    const res = await fetch(`${url}/api/${endpoint}/${company}`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return await res.json();
}

export async function fetchDevices(msalInstance, company) {
    return callBackend(msalInstance, company, "devices");
}

export async function fetchDeviceFaults(msalInstance, company) {
    return callBackend(msalInstance, company, "faults/devices");
}

export async function fetchDoorFaults(msalInstance, company) {
    return callBackend(msalInstance, company, "faults/doors");
}

export async function triggerReport(msalInstance, site_id) {
    const req = {
        scopes: ['https://aiotfiredoors.onmicrosoft.com/2426fcdb-500c-471d-9f98-b3f83368b81f/Reports.Create'],
        account: msalInstance.getActiveAccount(),
    };

    const token = (await msalInstance.acquireTokenSilent(req)).accessToken;
    console.log(token);
    
    await fetch(`https://fire-door-sensor-functions.azurewebsites.net/api/generate_report_manually?site=${site_id}`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    });
}