import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import TopBar from "../components/TopBar";

export default function Root() {
    const topBarHeight = "64px";
    
    return (
        <Box sx={{ display: 'flex', flexDirection: "column", height: "100vh", width: "100%" }}>
            <TopBar height={topBarHeight} />
            <Box
                component="main"
                sx={{ mt: topBarHeight, p: 3 }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}