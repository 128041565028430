import { MsalProvider } from "@azure/msal-react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { grey } from "@mui/material/colors";
import { QueryClient, QueryClientProvider } from 'react-query';

import Protected from "./components/Protected";
import Home from "./routes/Home";
import Root from "./routes/Root";
import Logout from "./routes/Logout";

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#009ca6',
        },
        secondary: {
            main: '#ffffff',
        },
        background: {
            default: grey[50],
        },
    },
});

export default function App({ msalInstance }) {
    const router = createBrowserRouter([
        // Routes not requiring authentication should be added here
        {
            path: "/logout",
            element: <Logout />,
        },

        // Routes which require authentication should be added as children of the <Protected /> element
        {
            element: <Protected />,
            children: [
                {
                    element: <Root />,
                    children: [
                        {
                            path: "/home",
                            element: <Home />,
                        },
                        // Catch-all route -- redirect to the homepage if no matches
                        {
                            path: "*",
                            element: <Navigate to="/home" replace />
                        },
                    ],
                },
            ],
        },
    ]);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <CssBaseline />
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </ThemeProvider>
        </MsalProvider>

    );
}
