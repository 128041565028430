import { Link } from "react-router-dom";
import { Box, Typography, Button, Stack } from "@mui/material";
import { useEffect } from 'react';

export default function Logout() {

    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            window.location.replace("https://www.autonomous-iot.com/");
        }, 5000);

        return () => clearTimeout(redirectTimeout);
    })

    return (
        <Box sx={{
            display: "flex"
        }}>
            <Box sx={{ position: "absolute", marginRight: "auto", marginLeft: 2, marginTop: 2 }}>
                <img src="aiot-logo.png" alt="AIOT logo" height="60px" width="173px" />
            </Box>
            <Stack direction="column" justifyContent="center" alignItems="center" width="100%" height="100vh" gap={3}>
                <Typography variant="h5">You have been logged out.</Typography>
                <Typography variant="subtitle1">You will be redirected within 5 seconds.</Typography>
                <Stack direction="row" gap={3}>
                    <Button
                        variant="outlined"
                        component={Link}
                        to="/home"
                        disableElevation
                    >
                        Log back in
                    </Button>
                    <Button
                        variant="contained"
                        component={Link}
                        to="https://www.autonomous-iot.com/"
                        disableElevation
                    >
                        Close
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}