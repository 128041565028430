import { useMsal } from '@azure/msal-react';
import { AppBar, Divider, Box, Typography, Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";

export default function TopBar({ height }) {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleAccountClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleAccountClose = () => {
        setAnchorEl(null);
    }

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <AppBar
            color="secondary"
            elevation={0}
            sx={{
                display: "flex",
                position: "absolute",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                height: height,
                borderBottom: "1px solid rgb(226 232 240)",
                py: 2
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", gap: 1.5, pl: 3 }}>
                <img src='aiot-logo-cr.png' height="30px" width="33px" title='logo' alt='logo' />
                <Divider variant='middle' orientation='vertical' flexItem />
                <Typography variant="h6" noWrap>Door Monitor</Typography>
            </Box>
            <Button
                onClick={handleAccountClick}
                variant="text"
                size="large"
                startIcon={<AccountCircleOutlined />}
                sx={{ mr: "24px", textTransform: "none" }}
            >
                {instance.getActiveAccount()?.name}
            </Button>
            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleAccountClose}
            >
                <MenuItem onClick={handleLogout}>Sign out</MenuItem>
            </Menu>
        </AppBar>
    );
}
