import { DataGrid as OriginalGrid } from "@mui/x-data-grid";

export default function DataGrid({ sx, ...props }) {
    return (
        <OriginalGrid
            autoHeight
            disableRowSelectionOnClick
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        site_id: false,
                    },
                },
            }}
            {...props}
            sx={{...sx,
                border: "none",
                /* Remove the menu and sort icons on mobile to prevent the headers from truncating */
                '@media (hover: none)': {
                    '&& .MuiDataGrid-menuIcon': {
                        width: 0,
                        visibility: 'hidden',
                    },
                    '&& .MuiDataGrid-sortIcon': {
                        width: 0,
                        visibility: 'hidden',
                    }
                },
                '&& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-menuIcon': {
                    width: 'auto',
                    visibility: 'visible',
                },
                '&& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon': {
                    width: 'auto',
                    visibility: 'visible',
                }
            }}
        />
    );
}